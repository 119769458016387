require('./bootstrap');

require('bootstrap');
require('jquery');
require('jquery.easing');

require('../../node_modules/startbootstrap-sb-admin-2/js/sb-admin-2.min');

require('../../node_modules/datatables.net/js/jquery.dataTables.min');
require('../../node_modules/datatables.net-bs4/js/dataTables.bootstrap4.min');
require('datatables.net');

$(document).ready(function () {
    $('.dataTable').DataTable();
});
